<template>
  <v-container fill-height>
    <v-row class="justify-center">
      <v-col xs="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="pa-6" color="foreground" elevation="1">
          <v-card-title class="font-weight-regular px-0 pt-0">
            <v-btn
              class="rounded-lg mr-3"
              icon
              small
              @click="navigation('/checkout')"
            >
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>

            Boas vindas,
          </v-card-title>

          <v-card-subtitle> Acesse sua conta LogusPay </v-card-subtitle>

          <v-form v-model="valid" ref="form" autocomplete="off">
            <v-text-field
              v-model="form.username"
              label="E-mail"
              backgroundColor="accent"
              name="e-mail"
              outlined
            >
              <template v-slot:append>
                <v-icon color="secondary"> mdi-email-outline </v-icon>
              </template>
            </v-text-field>

            <v-text-field
              v-model="form.password"
              label="Senha"
              backgroundColor="accent"
              :type="show ? 'text' : 'password'"
              outlined
            >
              <template v-slot:append>
                <v-icon color="secondary" @click:append="show = !show">
                  {{ show ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
                </v-icon>
              </template>
            </v-text-field>

            <v-btn class="mb-6" color="primary" pixCode block large @click="submit()">
              Entrar
            </v-btn>

            <div class="text-center text-body-2 secondary_text--text">
              Não consegue acessar? <a>Clique aqui.</a>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      valid: false,
      show: false,
      form: {
        username: "",
        password: "",
      },
    };
  },

  methods: {
    submit() {
      console.log("submit");
    },

    navigation(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style></style>
